import React from 'react'
import PropTypes from 'prop-types'

import styles from './InfoBar.module.scss'

const OpenStatus = ({ closedText, openText, storeOpen }) => {
  const classNames = {
    openStatus: [
      styles.openStatus,
      storeOpen ? styles.open : styles.closed,
    ].join(' '),
  }

  return (
    <div className={classNames.openStatus}>
      {storeOpen ? openText : closedText}
    </div>
  )
}

OpenStatus.propTypes = {
  closedText: PropTypes.string.isRequired,
  openText: PropTypes.string.isRequired,
  storeOpen: PropTypes.bool,
}

export default OpenStatus
