const icons = {
  caret: 'M315.7 0L196.3 119.5 588.8 512 196.3 904.5 315.7 1024l512-512L315.7 0z',
  chevronLeft: 'M828.4 120.7l-120.7-120.7 -512 512 512 512 120.7-120.7 -391.3-391.3 391.3-391.3Z',
  chevronRight: 'M316.4 0l-120.7 120.7 391.3 391.3 -391.3 391.3 120.7 120.7 512-512 -512-512Z',
  close: 'M1024 89L935 0 512 423 89 0 0 89l423 423L0 935l89 89 423-423 423 423 89-89L601 512 1024 89z',
  mail: 'M829.1 710.3c-4.1 0-8.1-1.5-11.3-4.5l-190-178.2c-6.6-6.2-7-16.7-0.7-23.3 6.2-6.7 16.6-7 23.3-0.7l190 178.2c6.6 6.2 7 16.7 0.7 23.3C837.9 708.5 833.5 710.3 829.1 710.3zM194.9 710.3c-4.4 0-8.8-1.8-12.1-5.2 -6.2-6.6-5.9-17.1 0.7-23.3l190.1-178.2c6.7-6.2 17.1-5.9 23.3 0.7 6.2 6.6 5.9 17.1-0.7 23.3L206.2 705.8C203 708.8 198.9 710.3 194.9 710.3zM941.5 876H82.5C37 876 0 839 0 793.4V230.6C0 185 37 148 82.5 148h858.9c45.5 0 82.5 37 82.5 82.5v562.9C1024 839 987 876 941.5 876zM82.5 181C55.2 181 33 203.3 33 230.6v562.9c0 27.3 22.2 49.5 49.5 49.5h858.9c27.3 0 49.5-22.2 49.5-49.5V230.6c0-27.3-22.2-49.5-49.5-49.5H82.5zM512 595c-22 0-43.9-7.3-60.6-21.9l-424-369.9c-6.9-6-7.6-16.4-1.6-23.3 6-6.9 16.4-7.5 23.3-1.6l424 369.8c21.1 18.4 56.8 18.4 77.8 0l423.4-369.2c6.8-6 17.2-5.3 23.3 1.6 6 6.9 5.3 17.3-1.6 23.3L572.6 573.1C555.9 587.7 534 595 512 595z',
  mappin: 'M515.3 224.4c-72.2 0-130.9 58.7-130.9 130.9s58.7 130.9 130.9 130.9 130.9-58.7 130.9-130.9S587.5 224.4 515.3 224.4zM515.3 448.8c-51.6 0-93.5-41.9-93.5-93.5s41.9-93.5 93.5-93.5 93.5 41.9 93.5 93.5S566.9 448.8 515.3 448.8zM765.6 105C697.9 37.3 607.8 0 512 0c-95.8 0-185.8 37.3-253.6 105 -125.4 125.3-140.9 361.1-33.7 504L512 1024l286.9-414.4C906.5 466.2 891 230.4 765.6 105zM768.6 587.8L512 958.3l-257-371.1c-97.2-129.6-83.3-342.6 29.8-455.7C345.6 70.8 426.2 37.4 512 37.4s166.5 33.4 227.1 94.1C852.3 244.6 866.2 457.6 768.6 587.8z',
  nav: 'M0 853.3h1024V739.6H0V853.3zM0 568.9h1024V455.1H0V568.9zM0 170.7v113.8h1024V170.7H0z',
  phone: 'M1023.5 807.9c2.2 16.6-2.9 31.1-15.2 43.4l-144.2 143.1c-6.5 7.2-15 13.4-25.5 18.4 -10.5 5.1-20.8 8.3-30.9 9.8 -0.7 0-2.9 0.2-6.5 0.5 -3.6 0.4-8.3 0.5-14.1 0.5 -13.7 0-36-2.3-66.7-7 -30.7-4.7-68.3-16.3-112.7-34.7 -44.5-18.4-94.9-46.1-151.2-82.9 -56.4-36.9-116.4-87.4-179.9-151.8 -50.6-49.9-92.5-97.6-125.7-143.1 -33.2-45.5-60-87.6-80.2-126.3 -20.2-38.7-35.4-73.7-45.5-105.2 -10.1-31.4-17-58.5-20.6-81.3 -3.6-22.8-5.1-40.7-4.3-53.7 0.7-13 1.1-20.2 1.1-21.7 1.4-10.1 4.7-20.4 9.8-30.9 5.1-10.5 11.2-19 18.4-25.5l144.2-144.2c10.1-10.1 21.7-15.2 34.7-15.2 9.4 0 17.7 2.7 24.9 8.1 7.2 5.4 13.4 12.1 18.4 20.1l116 220.1c6.5 11.6 8.3 24.2 5.4 37.9 -2.9 13.7-9 25.3-18.4 34.7l-53.1 53.1c-1.4 1.4-2.7 3.8-3.8 7 -1.1 3.3-1.6 6-1.6 8.1 2.9 15.2 9.4 32.5 19.5 52 8.7 17.3 22 38.5 40.1 63.4 18.1 24.9 43.7 53.7 77 86.2 32.5 33.2 61.4 59.1 86.7 77.5 25.3 18.4 46.4 32 63.4 40.7 17 8.7 30 13.9 39 15.7l13.5 2.7c1.4 0 3.8-0.5 7-1.6 3.3-1.1 5.6-2.3 7-3.8l61.8-62.9c13-11.6 28.2-17.3 45.5-17.3 12.3 0 22 2.2 29.3 6.5l1.1 0 209.2 123.6c15.2 9.4 24.2 21.3 27.1 35.8Z',

  socialFacebook: 'M767.957,0.218l-132.788,-0.213c-149.183,0 -245.592,98.913 -245.592,252.005l0,116.192l-133.513,0c-11.537,0 -20.88,9.353 -20.88,20.89l0,168.348c0,11.537 9.353,20.88 20.88,20.88l133.513,0l0,424.795c0,11.537 9.342,20.88 20.879,20.88l174.197,0c11.537,0 20.88,-9.354 20.88,-20.88l0,-424.795l156.107,0c11.537,0 20.88,-9.343 20.88,-20.88l0.064,-168.348c0,-5.539 -2.205,-10.845 -6.115,-14.765c-3.91,-3.92 -9.236,-6.125 -14.776,-6.125l-156.16,0l0,-98.497c0,-47.342 11.281,-71.375 72.951,-71.375l89.452,-0.031c11.527,0 20.869,-9.354 20.869,-20.88l0,-156.321c0,-11.516 -9.332,-20.858 -20.848,-20.88Z',
  socialInstagram: 'M741.403,0c155.826,0.001 282.597,126.772 282.591,282.585l0,458.824c0,155.813 -126.765,282.584 -282.591,282.585l-458.824,0c-155.814,0 -282.579,-126.765 -282.579,-282.585l0,-458.824c0,-155.813 126.765,-282.584 282.579,-282.585l458.824,0Zm0,90.854l-458.824,0c-105.718,0 -191.725,86.014 -191.725,191.731l0,458.824c0,105.723 86.008,191.737 191.725,191.731l458.83,0c105.723,0 191.737,-86.008 191.737,-191.731l-0.006,0l0,-458.824c0,-105.717 -86.013,-191.731 -191.737,-191.731Zm-229.406,157.298c-145.493,0 -263.857,118.364 -263.857,263.857c0,145.487 118.364,263.845 263.857,263.845c145.493,0 263.857,-118.358 263.857,-263.845c0,-145.493 -118.364,-263.857 -263.857,-263.857Zm0,436.843c-95.39,0 -173.004,-77.602 -173.004,-172.992c0,-95.396 77.608,-173.004 173.004,-173.004c95.396,0 173.004,77.608 173.004,173.004c0,95.39 -77.614,172.992 -173.004,172.992Zm274.923,-513.881c-17.504,0 -34.7,7.086 -47.062,19.503c-12.423,12.356 -19.564,29.558 -19.564,47.123c0,17.51 7.147,34.706 19.564,47.123c12.356,12.356 29.558,19.503 47.062,19.503c17.565,0 34.706,-7.147 47.123,-19.503c12.417,-12.417 19.503,-29.619 19.503,-47.123c0,-17.565 -7.086,-34.767 -19.503,-47.123c-12.356,-12.417 -29.558,-19.503 -47.123,-19.503Z',
  socialLinkedin: 'M1024,622.668l0,378.579l-219.476,0l0,-353.224c0,-88.733 -31.745,-149.29 -111.188,-149.29c-60.645,0 -96.735,40.811 -112.612,80.289c-5.79,14.113 -7.28,33.756 -7.28,53.512l0,368.713l-219.551,0c0,0 2.957,-598.246 0,-660.229l219.529,0l0,93.585c-0.445,0.7 -1.024,1.455 -1.443,2.133l1.443,0l0,-2.133c29.167,-44.922 81.255,-109.095 197.845,-109.095c144.447,-0.003 252.733,94.363 252.733,297.16Zm-899.765,-599.913c-75.108,0 -124.235,49.265 -124.235,114.05c0,63.373 47.705,114.133 121.321,114.133l1.466,0c76.563,0 124.177,-50.75 124.177,-114.133c-1.442,-64.785 -47.614,-114.05 -122.729,-114.05Zm-111.196,978.492l219.467,0l0,-660.229l-219.467,0l0,660.229Z',
  socialTwitter: 'M1024,194.523c-37.689,16.7 -78.129,28.026 -120.618,33.083c43.385,-25.98 76.594,-67.188 92.334,-116.139c-40.695,24.059 -85.616,41.529 -133.48,50.999c-38.328,-40.888 -92.846,-66.356 -153.315,-66.356c-116.01,0 -210.072,94.063 -210.072,210.009c0,16.444 1.855,32.505 5.439,47.864c-174.558,-8.768 -329.345,-92.4 -432.941,-219.481c-18.109,30.971 -28.411,67.059 -28.411,105.581c0,72.883 37.113,137.191 93.423,174.814c-34.426,-1.151 -66.804,-10.621 -95.15,-26.363l0,2.624c0,101.741 72.435,186.652 168.48,205.978c-17.597,4.735 -36.153,7.359 -55.349,7.359c-13.565,0 -26.683,-1.344 -39.545,-3.904c26.746,83.505 104.301,144.228 196.188,145.893c-71.859,56.31 -162.466,89.776 -260.879,89.776c-16.956,0 -33.658,-1.024 -50.102,-2.88c92.975,59.702 203.354,94.511 321.987,94.511c386.423,0 597.648,-320.068 597.648,-597.648l-0.704,-27.194c41.271,-29.437 76.976,-66.421 105.067,-108.526Z',
  socialYoutube: 'M980.48,227.84c-27.776,-49.408 -57.92,-58.496 -119.296,-61.952c-61.312,-4.16 -215.488,-5.888 -349.056,-5.888c-133.824,0 -288.064,1.728 -349.312,5.824c-61.248,3.52 -91.456,12.544 -119.488,62.016c-28.608,49.344 -43.328,134.336 -43.328,283.968l0,0.512c0,148.992 14.72,234.624 43.328,283.456c28.032,49.408 58.176,58.368 119.424,62.528c61.312,3.584 215.552,5.696 349.376,5.696c133.568,0 287.744,-2.112 349.12,-5.632c61.376,-4.16 91.52,-13.12 119.296,-62.528c28.864,-48.832 43.456,-134.464 43.456,-283.456c0,0 0,-0.192 0,-0.32c0,0 0,-0.128 0,-0.192c0,-149.696 -14.592,-234.688 -43.52,-284.032Zm-596.48,476.16l0,-384l320,192l-320,192Z',
}

export default icons
