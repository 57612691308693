import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import styles from './InfoBar.module.scss'

const SocialMedia = ({ platforms }) => (
  <div className={styles.socialMedia}>
    {platforms.map((item) => (
      <a
        key={item.title}
        href={item.url}
        target="_blank"
        rel="nofollow noopener noreferrer"
        alt={item.title}
      >
        <Icon name={item.icon} />
      </a>
    ))}
  </div>
)

SocialMedia.propTypes = {
  platforms: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default SocialMedia
