import React from 'react'
import PropTypes from 'prop-types'

import styles from './InfoBar.module.scss'

const Contact = ({ phoneHtml, phoneHuman }) => (
  <div className={styles.contact}>
    <a href={`tel:${phoneHtml}`}>{phoneHuman}</a>
  </div>
)

Contact.propTypes = {
  phoneHtml: PropTypes.string.isRequired,
  phoneHuman: PropTypes.string.isRequired,
}

export default Contact
