export default {
  meta: {
    description: 'Een winkel voor spannende en draagbare dameskleding in het centrum van Cuijk, met een ervaren en deskundig verkoopteam.',
    keywords: 'per,tutti,pertutti,kleding,mode,dames,vrouwen,boutique,cuijk',
  },

  misc: {
    closedText: 'Nu gesloten',
    copyright: '© 2020 Per Tutti | KvK: 16047851',
    email: 'info@pertuttimode.nl',
    address: "Per Tutti\nKorte Molenstraat 9b\n5431 DT Cuijk",
    openText: 'Nu open!',
    // phoneHtml: '+31485321773',
    // phoneHuman: '0485 - 32 17 73',
    phoneHtml: '+31627056827',
    phoneHuman: '06 - 27 05 68 27',
  },

  navigation: [
    { title: 'Over ons', path: '#overons' },
    { title: 'Inspiratie', path: '#inspiratie' },
    { title: 'Het team', path: '#hetteam' },
    { title: 'Openingstijden', path: '#openingstijden' },
    { title: 'Nieuwsbrief', path: '#nieuwsbrief' },
    { title: 'Contact', path: '#contact' },
  ],

  social: [
    { title: 'Facebook', url: 'https://www.facebook.com/Per-Tutti-mode-434659443212728/', icon: 'socialFacebook' },
    { title: 'Instagram', url: 'https://www.instagram.com/pertutti_mode/', icon: 'socialInstagram' },
  ],

  testimonials: [
    {
      id: 1,
      text: 'Fusce lacinia urna risus primis a massa consectetur adipiscing dui consectetur scelerisque fusce cras dictumst posuere.',
      authorName: 'Author',
      authorFunction: 'Function - Company'
    },
    {
      id: 2,
      text: 'Natoque dapibus pulvinar varius gravida pretium curabitur cubilia vestibulum a a consectetur.',
      authorName: 'Author',
      authorFunction: 'Function - Company'
    },
  ],

  index: {
    storefront: 'voorgevel',

    aboutUs: {
      title: 'Over ons',
      subtitle: 'Van harte welkom!',
      anchor: 'overons',
      p1: 'Per Tutti Mode vindt u in het mooie Brabantse dorp Cuijk aan de Maas. Onze winkel is gevestigd in een karakteristiek oud pand midden in Cuijk. In onze gezellige winkel verkopen wij een spannende mix van mooie en draagbare vrouwenkleding.',
      p2: 'Laat u verrassen, adviseren en inspireren!',
    },

    inspiration: {
      anchor: 'inspiratie',
      viewOnInstagram: 'Bekijk op Instagram'
    },

    theTeam: {
      title: 'Het team',
      subtitle: 'Ervaren en deskundig advies',
      anchor: 'hetteam',
      p1: 'Door een ervaren en deskundig verkoopteam wordt u begeleid en geadviseerd in het maken van uw keuze van kleding die u wilt dragen.',
      p2: 'Onze inkoopkeuze komt voort uit verschillende bronnen zoals beurzen, bladen, interesse en plezier in mode en kleding en passie voor het vak. Een collectie van vrouwenkleding, tassen, schoenen en accessoires van bekende merken vormen een uniek geheel.',
    },

    businessHours: {
      title: 'Openingstijden',
      anchor: 'openingstijden',
      closed: 'Gesloten',
      signOpen: 'bordje nu open!',
      signClosed: 'bordje nu gesloten',
      hours: 'uur',
      shoppingSundays: {
        url: 'https://cuijk.nu/koopzondag/',
        urlText: 'hier',
        text: 'Klik [url] voor een overzicht van alle koopzondagen.'
      },
      days: {
        monday: 'Maandag',
        tuesday: 'Dinsdag',
        wednesday: 'Woensdag',
        thursday: 'Donderdag',
        friday: 'Vrijdag',
        saturday: 'Zaterdag',
        sunday: 'Zondag',
      },
    },

    newsletter: {
      title: 'Nieuwsbrief',
      subtitle: 'Alle acties en evenementen',
      anchor: 'nieuwsbrief',
      placeholderEmail: 'Voer hier uw e-mailadres in',
      submit: 'Inschrijven',
    },

    contact: {
      title: 'Contact',
      subtitle: 'Wij staan voor u klaar!',
      anchor: 'contact',
      labelName: 'Uw naam',
      labelEmail: 'Uw e-mailadres',
      labelMessage: 'Uw bericht',
      labelSend: 'Verzenden',
      sendSuccess: 'Uw bericht is verzonden, wij nemen zo spoedig mogelijk contact met u op.',
      // sendError: 'Er is iets misgegaan bij het versturen van uw bericht.\nBel ons op 0485 - 32 17 73 of stuur een e-mail naar info@pertuttimode.nl',
      sendError: 'Er is iets misgegaan bij het versturen van uw bericht.\nBel ons op 06 - 27 05 68 27 of stuur een e-mail naar info@pertuttimode.nl',
    },
  },

  notFound: {
    title: 'Deze pagina bestaat niet (meer).',
    link: 'Terug naar de home-pagina'
  }
}
