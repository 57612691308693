import React from 'react'
import PropTypes from 'prop-types'

import styles from './Footer.module.scss'

const Footer = ({ i18n }) => (
  <footer className={styles.footer}>
    <div className="wrapper-l t-center">{i18n.misc.copyright}</div>
  </footer>
)

Footer.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Footer
