import businessHoursSchedule from '../assets/businessHoursSchedule.json'

const storeOpen = () => {
  const now = new Date()
  const open = new Date()
  const closed = new Date()

  const today = businessHoursSchedule[now.getDay()]

  if (today.open && today.closed) {
    const todayOpen = today.open.split('.')
    const todayClosed = today.closed.split('.')

    open.setHours(todayOpen[0])
    open.setMinutes(todayOpen[1])

    closed.setHours(todayClosed[0])
    closed.setMinutes(todayClosed[1])

    if (now.getTime() >= open.getTime() && now.getTime() <= closed.getTime()) {
      return true
    }
  }

  return false
}

export default storeOpen
