import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'

import logo from '../../assets/logo.svg'

import styles from './Header.module.scss'

const Header = ({ i18n }) => {
  const scrollIntoView = (selector) => {
    const target = document.querySelector(selector)

    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <header className={styles.header}>
      <div className="wrapper-l">
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>

        <nav className={styles.pageNav}>
          {i18n.navigation.map((item) => (
            <Button
              key={item.path}
              onClick={() => scrollIntoView(item.path)}
              type="button"
              color="text"
              size="small"
              inline
            >
              {item.title}
            </Button>
          ))}
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  i18n: PropTypes.object.isRequired,
}

export default Header
