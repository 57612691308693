import React from 'react'
import PropTypes from 'prop-types'

import Contact from './Contact'
import OpenStatus from './OpenStatus'
import SocialMedia from './SocialMedia'

import styles from './InfoBar.module.scss'

const InfoBar = ({ i18n, storeOpen }) => (
  <div className={styles.infoBar}>
    <div className="wrapper-l">
      <SocialMedia platforms={i18n.social} />

      <OpenStatus
        closedText={i18n.misc.closedText}
        openText={i18n.misc.openText}
        storeOpen={storeOpen}
      />

      <Contact
        phoneHtml={i18n.misc.phoneHtml}
        phoneHuman={i18n.misc.phoneHuman}
      />
    </div>
  </div>
)

InfoBar.propTypes = {
  i18n: PropTypes.object.isRequired,
  storeOpen: PropTypes.bool,
}

export default InfoBar
